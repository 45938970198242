import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'
import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { getReorderDestinationIndex } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index'
import {
  attachClosestEdge,
  extractClosestEdge,
  type Edge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import type { CleanupFn } from '@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types'
import { disableNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/disable-native-drag-preview'
import { preventUnhandled } from '@atlaskit/pragmatic-drag-and-drop/prevent-unhandled'
import { onWatcherCleanup } from 'vue'
import { pointerOutsideOfPreview } from '@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview'
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview'
import type { ElementState, TargetElement } from '#core/types'
import type { ResizeState } from '#board/types'
import { DRAG_SCROLL_ENABLED_ATTRIBUTE } from '#core/constant'

/**
 * useDragAndDrop is a utility composable for basic case like as reorder items in a array
 * Note: dndKey: A unique key to check "can drop condition", element with same dndKey can share drop zone
 */
export const useDragAndDrop = (params: {
  dndKey: string
  target: TargetElement
  dragHandleTarget?: TargetElement
  index: number | Ref<number>
  axis: 'vertical' | 'horizontal'
  disableDrag?: boolean
  onDrop?: (params: { startIndex: number; finishIndex: number }) => void
}) => {
  const cleanup = ref<CleanupFn>()
  const state = ref<ElementState>({
    type: 'idle',
  })
  const closestEdge = ref<Edge | null>(null)

  onMounted(() => {
    const {
      dndKey: key,
      target,
      dragHandleTarget,
      index: rawIndex,
      axis,
      disableDrag,
    } = params

    if (disableDrag) {
      return
    }

    const element = unrefElement(evaluativeFn(target))
    const dragHandle = unrefElement(evaluativeFn(dragHandleTarget))
    const index = () => unref(rawIndex)
    const reactiveData = () => {
      return {
        type: key,
        index: index(),
      }
    }

    cleanup.value = combine(
      draggable({
        element,
        dragHandle,
        getInitialData: reactiveData,
        onDragStart: () => {
          state.value = {
            type: 'dragging',
          }
        },
        onGenerateDragPreview: ({ source, nativeSetDragImage }) => {
          const rect = source.element.getBoundingClientRect()
          setCustomNativeDragPreview({
            nativeSetDragImage,
            getOffset: pointerOutsideOfPreview({
              x: '4px',
              y: '4px',
            }),
            render({ container }) {
              container.style.cursor = 'grabbing'
              state.value = {
                type: 'preview',
                container,
                rect,
              }
              return () => {
                state.value = {
                  type: 'dragging',
                }
              }
            },
          })
        },
        onDrop: () => {
          state.value = {
            type: 'idle',
          }
        },
      }),
      dropTargetForElements({
        element,
        canDrop: (args) => args.source.data.type === key,
        getIsSticky: () => true,
        getData: ({ input, element }) => {
          return attachClosestEdge(reactiveData(), {
            input,
            element,
            allowedEdges:
              axis === 'vertical' ? ['top', 'bottom'] : ['left', 'right'],
          })
        },
        onDragEnter: (args) => {
          const item = args.source.data
          if (item?.index !== index()) {
            closestEdge.value = extractClosestEdge(args.self.data)
          }
        },
        onDrag: (args) => {
          const item = args.source.data
          if (item?.index !== index()) {
            closestEdge.value = extractClosestEdge(args.self.data)
          }
        },
        onDragLeave: () => {
          closestEdge.value = null
        },
        onDrop: ({ self, source }) => {
          closestEdge.value = null
          const currentClosestEdge = extractClosestEdge(self.data)
          const startIndex = source.data.index as number
          const finishIndex = getReorderDestinationIndex({
            startIndex,
            indexOfTarget: index(),
            closestEdgeOfTarget: currentClosestEdge,
            axis,
          })
          params.onDrop?.({
            startIndex,
            finishIndex,
          })
        },
      })
    )
  })

  onBeforeUnmount(() => {
    cleanup.value?.()
  })

  return {
    state,
    closestEdge,
  }
}

/**
 * useResizeElement is a utility composable for resizing elements
 * @param params
 */
export const useResizeElement = (params: {
  resizerRef: Ref<HTMLElement>
  elementRef: Ref<HTMLElement>
  minWidth: number | (() => number)
  maxWidth: number | (() => number)
  disableResize?: boolean
  hitBoxWitdth?: number
  onResize?: (width: number) => void
  onRelease?: (width: number) => void
}) => {
  const {
    resizerRef,
    elementRef,
    minWidth,
    maxWidth,
    disableResize,
    hitBoxWitdth = 24,
    onRelease,
    onResize,
  } = params
  const idleState: ResizeState = { type: 'idle' }
  const hitSlop = hitBoxWitdth / 2

  const resizerRight = useCssVar('--right', resizerRef)
  const state = ref<ResizeState>(idleState)
  const resizeGridStyle = ref()
  const cleanup = ref<CleanupFn>()

  const renderResizeHandle = computed(
    () => state.value.type === 'idle' || state.value.type === 'resizing'
  )
  watch(
    () => [renderResizeHandle.value, resizerRef.value],
    ([canRenderResize]) => {
      if (!canRenderResize || !resizerRef.value || disableResize) {
        return
      }

      cleanup.value = draggable({
        element: resizerRef.value as HTMLElement,
        getInitialData() {
          return {
            type: 'resize',
          }
        },
        onGenerateDragPreview({ nativeSetDragImage }) {
          disableNativeDragPreview({ nativeSetDragImage })
          preventUnhandled.start()
          const header = elementRef.value
          if (header) {
            const { width } = header.getBoundingClientRect()
            state.value = {
              type: 'resizing',
              initialWidth: width,
            }
          }
        },
        onDrag({ location }) {
          const { current, initial } = location
          const diffX = current.input.clientX - initial.input.clientX
          const minW = evaluativeFn(minWidth)
          const maxW = evaluativeFn(maxWidth)
          if (state.value.type === 'resizing') {
            const { initialWidth } = state.value
            const proposedWidth = clamp({
              value: initialWidth + diffX,
              min: initialWidth < minW ? initialWidth : minW,
              max: maxW,
            })
            const dragPos = -diffX - hitSlop
            let resizerRightPos = dragPos
            if (dragPos > 0 && initialWidth - dragPos <= minW + hitSlop) {
              resizerRightPos =
                initialWidth === proposedWidth
                  ? -hitSlop
                  : initialWidth - minW - hitSlop
            }

            if (dragPos <= 0 && initialWidth - dragPos >= maxW + hitSlop) {
              resizerRightPos = initialWidth - maxW - hitSlop
            }

            resizerRight.value = `${resizerRightPos}px`
            const resizeRect = resizerRef.value!.getBoundingClientRect()
            resizeGridStyle.value = {
              left: `${resizeRect.left + 12}px`,
              top: `${resizeRect.top}px`,
            }
            state.value.proposedWidth = proposedWidth
            onResize?.(proposedWidth)
          }
        },
        onDrop() {
          if (state.value.type === 'resizing') {
            resizerRight.value = `-${hitSlop}px`
            onRelease?.(state.value.proposedWidth || evaluativeFn(minWidth))
          }

          preventUnhandled.stop()
          state.value = idleState
        },
      })

      onWatcherCleanup(() => {
        cleanup.value?.()
      })
    },
    {
      immediate: true,
    }
  )

  onUnmounted(() => {
    cleanup.value?.()
  })

  return {
    state,
    renderResizeHandle,
    resizeGridStyle,
  }
}

/**
 * useEnableDragToScroll is a utility composable for enabling drag to scroll on an element
 */
export const useEnableDragToScroll = (params: {
  element: TargetElement
  options?: {
    fnIgnore: (e: MouseEvent) => boolean
  }
}) => {
  const offset = { x: 0, y: 0 }
  const startPos = { x: 0, y: 0 }

  const domElement = () => unrefElement(evaluativeFn(params.element))

  const onMouseMove = (e: MouseEvent) => {
    domElement().scrollLeft = offset.x + startPos.x - e.clientX
    domElement().scrollTop = offset.y + startPos.y - e.clientY

    return false
  }

  const onMouseUp = () => {
    document.removeEventListener('mousemove', onMouseMove)
    document.removeEventListener('mouseup', onMouseUp)

    return false
  }

  const onMouseDown = (e: MouseEvent) => {
    if (e.button !== 0 || params.options?.fnIgnore(e)) return

    startPos.x = e.clientX
    startPos.y = e.clientY
    offset.x = domElement().scrollLeft
    offset.y = domElement().scrollTop

    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('mouseup', onMouseUp)

    return false
  }

  onMounted(() => {
    const element = domElement()
    if (element.getAttribute(DRAG_SCROLL_ENABLED_ATTRIBUTE) !== null) {
      return
    }

    element.setAttribute(DRAG_SCROLL_ENABLED_ATTRIBUTE, 'true')
    element.addEventListener('mousedown', onMouseDown)
  })

  onUnmounted(() => {
    const element = domElement()
    element?.removeEventListener('mousedown', onMouseDown)
    element?.removeAttribute(DRAG_SCROLL_ENABLED_ATTRIBUTE)
  })
}
